import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";

export default {
	data() {
		return {
			isContentReady: false,
			printImage: null
		};
	},
	mounted() {
        this.initPaper();
    },
	methods: {
		async initPaper() {
            setTimeout(() => {
                html2canvas(this.$refs.printElement, { scale: 4, quality: 1, useCORS: true }).then(canvas => {
                    const paper = canvas.toDataURL('image/jpeg');

                    this.printImage = paper;
                    this.isContentReady = true;
                });
            }, 300);
        },
		async downloadPDF(prefix) {
			let loader = this.$modalLoader.render();

			const doc = new jsPDF('p', 'mm', 'a4');
			const width = doc.internal.pageSize.getWidth();
			const height = doc.internal.pageSize.getHeight();

			doc.addImage(this.printImage, 'JPEG', 0, 0, width, height);
			doc.save(prefix + '-' + this.$route.params.id + '.pdf');

			loader.hide();
		},
		print() {
			let printPanel = document.getElementById('paperPrint').outerHTML;

			var iframeElement = document.createElement('iframe');
			iframeElement.name = 'printFrame';
			iframeElement.style.width = '0px';
			iframeElement.style.height = '0px';
			document.body.appendChild(iframeElement);
		
			var iframeContent = (iframeElement.contentWindow) ? iframeElement.contentWindow : (iframeElement.contentDocument.document) ? iframeElement.contentDocument.document : iframeElement.contentDocument;
			iframeContent.document.open();
			iframeContent.document.write('<style>@media print { @page { size: A4; margin: 0; } #paperPrint { width: 100% } }</style>');
			iframeContent.document.write(printPanel);
			iframeContent.document.close();
		
			setTimeout(() => {
				window.frames['printFrame'].focus();
				window.frames['printFrame'].print();
				document.body.removeChild(iframeElement);
			}, 500);
		}
	}
}